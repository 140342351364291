if(document.querySelector('.js-landing-content')) {
	const navBar = document.querySelector('.js-nav-bar');
	let scrollPosition = window.scrollY;

	document.addEventListener('DOMContentLoaded', () => {
		if (scrollPosition <= 0) {
			navBar.classList.add('nav-bar--not-scrolled');
		} else {
			navBar.classList.remove('nav-bar--not-scrolled');
		}
	});

	window.addEventListener('scroll', function() {
		scrollPosition = window.scrollY;
		if (scrollPosition <= 0) {
			navBar.classList.add('nav-bar--not-scrolled');
		} else {
			navBar.classList.remove('nav-bar--not-scrolled');
		}
	});
}