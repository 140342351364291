if(document.querySelector('.js-dom-loading')) {

	const loadingContainer = document.querySelector('.js-dom-loading');

	document.addEventListener("DOMContentLoaded",function(){
		console.log('pre-loaded');
		loadingContainer.classList.add('hide');
		console.log('loaded');
	});

}

if(document.querySelector('.js-loading')) {

	const loadingContainer = document.querySelector('.js-loading');

	loadingStart = function() {
		loadingContainer.classList.remove('hide');
	}

	loadingEnd = function() {
		setTimeout(function() {
			loadingContainer.classList.add('hide');
		}, 1000);
	}

}