if(document.querySelector('.js-view-pokemon')) {

	let clickThroughPokemon = function() {
		//Assign click events to the previous
		let previous = document.querySelectorAll('.js-view-pokemon-previous').forEach(el => {
			el.addEventListener('click',function (e) {
				let pokemonContainer = this.closest('.js-view-pokemon'); // Find this pokemon container
				let totalCount = pokemonContainer.dataset.pokemon_count_total; // get total
				let currentCountContainer = pokemonContainer.querySelector('.js-view-pokemon-current'); // get current value container
				let currentCount = currentCountContainer.getAttribute('value'); // get current
				pokemonContainer.querySelector('.js-pokemon-' + currentCount).classList.toggle('hide'); //hide current

				if(currentCount == 1) { previousCount = totalCount; } // if current count is max already, make max.
				else { previousCount = --currentCount; }
				pokemonContainer.querySelector('.js-pokemon-' + previousCount).classList.toggle('hide'); // show next
				currentCountContainer.innerHTML = previousCount; // Set the visible value.
				currentCountContainer.setAttribute('value', previousCount); // Set the invisible value.		
			});
		});

		//Assign click events to the next
		let next = document.querySelectorAll('.js-view-pokemon-next').forEach(el => {
			el.addEventListener('click',function (e) {

				let pokemonContainer = this.closest('.js-view-pokemon'); // Find this pokemon container
				let totalCount = pokemonContainer.dataset.pokemon_count_total; // get total
				let currentCountContainer = pokemonContainer.querySelector('.js-view-pokemon-current'); // get current value container
				let currentCount = currentCountContainer.getAttribute('value'); // get current
				pokemonContainer.querySelector('.js-pokemon-' + currentCount).classList.toggle('hide'); //hide current

				if(currentCount == totalCount) { nextCount = 1; } // if current count is max already, make next 1.
				else { nextCount = ++currentCount; }
				pokemonContainer.querySelector('.js-pokemon-' + nextCount).classList.toggle('hide'); // show next
				currentCountContainer.innerHTML = nextCount; // Set the visible value.
				currentCountContainer.setAttribute('value', nextCount); // Set the invisible value.


			});
		});
	}

	//Notes
	let pokemonNotes = function() {
		let noteTrigger = document.querySelectorAll('.js-note-trigger').forEach(el => {
			el.addEventListener('mouseover', function( e ) {
				let sibling = this.previousSibling;
				let parent = sibling.parentElement;
				let noteContainer = parent.querySelector('.js-note-container');
				noteContainer.classList.remove('hide');
			});

			el.addEventListener('mouseleave', function( e ) {
				let sibling = this.previousSibling;
				let parent = sibling.parentElement;
				let noteContainer = parent.querySelector('.js-note-container');
				noteContainer.classList.add('hide');
			});
		});
	}



	//filters
	let dropdowns = document.querySelectorAll('.js-dropdown-triggr').forEach(el => {
		el.addEventListener('click', function() {

			let alldropdowns = document.querySelectorAll('.js-dropdown-container');

			alldropdowns.forEach(a => {
				if(this.classList.contains('active-filter')){

				} else {
					a.querySelector('.js-dropdown-triggr').classList.remove('active-filter');
					a.querySelector('.js-dropdown-content').classList.add('hide');
				}
			});
			

			el.classList.toggle('active-filter'); //toggle chevron and classes on dropdown
			let container = el.closest('.js-dropdown-container'); // Get current Container
			let content = container.querySelector('.js-dropdown-content'); // get current cotntent
			content.classList.toggle('hide'); //show / hide content

		});
	});

	const filterContainer = document.querySelector('.js-active-filters');

	let options = document.querySelectorAll('.js-filter-option').forEach(el => { // create filter selection
		el.addEventListener('click', function() {

			this.classList.add('hide');

			let category = this.dataset.field;
			let value = this.getAttribute('value');
			let text = this.querySelector('span').innerHTML;

			let span = document.createElement('span');
			span.textContent = text;
			span.setAttribute('class', 'selected-filter js-selected-filter');
			span.setAttribute('value', value);
			span.setAttribute('data-category', category);
			filterContainer.appendChild(span);

		});
	});

	document.addEventListener('click', function(e) { //Remove the filter, add back into list.
		if(e.target && e.target.classList.contains('js-selected-filter')){
			value = e.target.getAttribute('value');
			category = e.target.dataset.category;
			filteredOption = document.querySelector('.js-filter-option[data-field="' + category + '"][value="' + value + '"]');
	    	filteredOption.classList.remove('hide');
	    	e.target.remove();
	    }
	});

	//Mobile filters
	let mobileFiltersTrigger = document.querySelectorAll('.js-mobile-chevron').forEach(el => {
		el.addEventListener('click', function() {
			document.querySelectorAll('.js-mobile-filters').forEach(e => {
				e.classList.toggle('block');
			});

			document.querySelector('i.js-mobile-chevron').classList.toggle('fa-chevron-down');
			document.querySelector('i.js-mobile-chevron').classList.toggle('fa-chevron-up');
		});
	});




	//Loop through the selected filters
	const apply = document.querySelector('.js-filters-apply');

	apply.addEventListener('click', function(e) {
		loadingStart();
		let filters = ""; // Create filters array
		let selectedFilters = document.querySelectorAll('.js-selected-filter');

		selectedFilters.forEach((filter) => {
			category = filter.dataset.category;
			value = filter.getAttribute('value');
			filters += '&' + category + '[]=' + value;
		});

		let trainer = document.querySelector('.js-stats-container').dataset.trainer;

		$.ajax({
			type:	'POST',
			url:	'../parts/view/filtered-pokemon.php?trainer=' + trainer + filters,
			success: function(res){
				window.scrollTo(0,0);
				$('.js-view-container').html(res);
				clickThroughPokemon();
				pokemonNotes();
				loadingEnd();
			}
		});

	});

	const clear = document.querySelector('.js-filters-clear');

	clear.addEventListener('click', function(e) {
		loadingStart();
		let trainer = document.querySelector('.js-stats-container').dataset.trainer;


		//clear the filters and add back to list.
		document.querySelectorAll('.js-selected-filter').forEach(el => {
			value = el.getAttribute('value');
			category = el.dataset.category;
			filteredOption = document.querySelector('.js-filter-option[data-field="' + category + '"][value="' + value + '"]');
	  		filteredOption.classList.remove('hide');
			el.remove();
		});





		$.ajax({
			type:	'POST',
			url:	'../parts/view/filtered-pokemon.php?trainer=' + trainer,
			success: function(res){
				window.scrollTo(0,0);
				$('.js-view-container').html(res);
				clickThroughPokemon();
				pokemonNotes();
				loadingEnd();
			}
		});
	});





	//LOAD SCRIPTS ON DOC LOAD
	document.addEventListener("load", clickThroughPokemon());
	document.addEventListener("load", pokemonNotes());


}

if(document.querySelector('.js-show-statistics')) {

	////////////// STATISTICS ///////////////
	const statisticTrigger = document.querySelector('.js-show-statistics');
	const statisticContainer = document.querySelector('.js-stats-container');

	let applyCloseLoad = function() {
		let close = document.querySelector('.js-statistics-close');
		close.addEventListener('click', function(e) {
			statisticContainer.classList.add('hide');
		});
	}

	let applyHuntHeight = function() {
		let longest = document.querySelector('.js-longest-hunt');
		let shortest = document.querySelector('.js-shortest-hunt');
		let divider = document.querySelector('.js-divider-hunt');
		let longestHeight = longest.offsetHeight;
		let shortestHeight = shortest.offsetHeight;
		let newHeight = Math.max(longestHeight,shortestHeight);

		longest.style.height = newHeight;
		shortest.style.height = newHeight;
		divider.style.height = newHeight - 50;
	}

	let applyOutsideClick = function() {
		let inner = document.querySelector('.js-stats-inner');
		statisticContainer.addEventListener('click', function(e) {
			let clickedInside = inner.contains(e.target);
			if(!clickedInside) {
				statisticContainer.classList.add('hide');
			}
		});
	}

	statisticTrigger.addEventListener('click', function(e) { //Add click to stats button

		if(document.querySelector('.js-statistics-present')) { //check if Dom has been created.
			
			statisticContainer.classList.remove('hide');

		} else {
			loadingStart();

			let trainer = document.querySelector('.js-stats-container').dataset.trainer;

			$.ajax({
				type:	'POST',
				url:	'../parts/view/view-statistics.php?trainer=' + trainer,
				success: function(res){
					$('.js-stats-container').html(res);
					applyHuntHeight();
					applyCloseLoad();
					applyOutsideClick();
					loadingEnd();
				}
			});

			statisticContainer.classList.remove('hide');
		}
	});
}
